import { NgModule } from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { BrowserModule, Title } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppComponent } from "./app.component";
import { AppRouterModule } from "./app-routing.module";
import { LocalStorageService } from "./core";
import { JwtModule } from "@auth0/angular-jwt";
import { IntercomModule } from "ng-intercom";
import { FacebookModule } from "ngx-facebook";
import { ServicesInterceptor } from "./services/services-interceptor";

import { BsModalService, ModalModule, BsModalRef } from "ngx-bootstrap";

import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { HttpClient } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { AppConfig } from "src/app.config";

import { CookieService } from "ngx-cookie-service";
import { APP_BASE_HREF } from "@angular/common";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";

export function tokenGetter() {
  return localStorage.getItem("access_token");
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, AppConfig.localizationFiles, ".json?cacheBuster=" + new Date().getTime());
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRouterModule,
    BrowserModule,
    RouterModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FacebookModule.forRoot(),
    RouterModule,
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: ["localhost:3001"],
        blacklistedRoutes: ["localhost:3001/auth/"],
      },
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    IntercomModule.forRoot({
      appId: AppConfig.IntercomAppId,
      updateOnRouterChange: true, // will automatically run `update` on router event changes. Default: `false`
    }),
  ],
  providers: [
    LocalStorageService,
    CookieService,
    BsModalService,

    Title,
    TranslateModule,
    BsModalRef,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServicesInterceptor,
      multi: true,
    },
    { provide: APP_BASE_HREF, useValue: AppConfig.BaseHref },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

declare global {
  interface Window {
    analytics: any;
  }
}
