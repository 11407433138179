import { Injectable, Optional, SkipSelf } from '@angular/core';
import { Subject } from 'rxjs/Subject';

export interface AutoSaveState {
  status: Status;
}

export enum Status {
  InProgress = 'Saving',
  Saved = 'Saved',
  Unsaved = 'Unsaved'
}

@Injectable({
  providedIn: 'root'
})
export class AutoSavingService {
  private autoSaveSubject = new Subject<AutoSaveState>();

  autoSaveState = this.autoSaveSubject.asObservable();

  constructor(@Optional() @SkipSelf() prior: AutoSavingService) {
    if (prior) { return prior; }
    this.inProgress();
  }

  inProgress() {
    this.autoSaveSubject.next(<AutoSaveState>{ status: Status.InProgress });
  }

  saved() {
    this.autoSaveSubject.next(<AutoSaveState>{ status: Status.Saved });
  }

  unsaved() {
    this.autoSaveSubject.next(<AutoSaveState>{ status: Status.Unsaved });
  }
}
