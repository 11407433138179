import { Injectable, Optional, SkipSelf } from '@angular/core';
import { Subject } from 'rxjs/Subject';

export interface LoaderState {
  show: boolean;
  isLayoutShown: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private loaderSubject = new Subject<LoaderState>();

  loaderState = this.loaderSubject.asObservable();

  constructor(@Optional() @SkipSelf() prior: LoaderService) {
    if (prior) { return prior; }
  }

  show(isLayoutShown: boolean = true) {
    this.loaderSubject.next(<LoaderState>{ show: true, isLayoutShown: isLayoutShown });
    // document.querySelector('body').classList.add('freeze');
  }

  hide() {
    const id = setTimeout(() => {
      this.loaderSubject.next(<LoaderState>{ show: false });
      clearTimeout(id);
    }, 1000);
    // document.querySelector('body').classList.remove('freeze');
  }
}
