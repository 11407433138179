import { Injectable, Output, EventEmitter } from "@angular/core";
import { Plan } from "../modules/payment/services/plan.model";
import { Referrals } from "../shared/enums/referrals.enum";
import { UsersFilters } from "../modules/layout/inbox/models/Filters.model";

@Injectable({
  providedIn:"root"
})
export class LocalStorageService {
  currentUserPic = "CurrentUserPicutre";
  token = "token";
  pageTitle = "pageTitle";
  currentCustomerId = "CurrentCustomerId";
  selectedPlan = "selectedPlan";

  @Output() currentUserChanged = new EventEmitter();
  @Output() pageTitleChanged = new EventEmitter();

  constructor() {}

  private setItem(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  private getItem(key) {
    try {
      const value = localStorage.getItem(key);
      return JSON.parse(value);
    } catch (e) {
      return false;
    }
  }

  private removeItem(key) {
    localStorage.removeItem(key);
  }

  clearAll() {
    localStorage.clear();
  }

  setCurrentUserPicutre(value: any) {
    this.setItem("CurrentUserPicutre", value);
    this.currentUserChanged.emit({ value: value });
  }
  getCurrentUserPicutre() {
    return this.getItem("CurrentUserPicutre");
  }

  setCurrentUserUsername(value: any) {
    this.setItem("CurrentUserUsername", value);
    this.currentUserChanged.emit({ value: value });
  }
  getCurrentUserUsername() {
    return this.getItem("CurrentUserUsername");
  }

  setCurrentCustomerId(value: any) {
    this.setItem("8AD37A39EE68FA4220602CD4D3D01FE4", value);
    this.currentUserChanged.emit({ value: value });
  }
  getCurrentCustomerId() {
    return this.getItem("8AD37A39EE68FA4220602CD4D3D01FE4");
  }
  setCurrentCustomerIdPlain(value: any) {
    this.setItem("8AD37A39EE68FA4220602CD400001FE4", value);
    this.currentUserChanged.emit({ value: value });
  }
  getCurrentCustomerIdPlain() {
    return this.getItem("8AD37A39EE68FA4220602CD400001FE4");
  }
  setCurrentCustomerEmail(value: any) {
    this.setItem("8AD37A39EE68FA4994602CD4D3D01FE4", value);
    this.currentUserChanged.emit({ value: value });
  }
  getCurrentCustomerEmail() {
    return this.getItem("8AD37A39EE68FA4994602CD4D3D01FE4");
  }
  setToken(value: any) {
    this.setItem("9F808C5307CF273ED62EB67BD033382F", value);
  }

  getToken() {
    if (this.getItem("9F808C5307CF273ED62EB67BD033382F") == null) {
      this.setToken("");
    }
    return this.getItem("9F808C5307CF273ED62EB67BD033382F");
  }

  setAuthId(value: any) {
    this.setItem("A321ASD56A4SD98QW98EQWE65Q3W2", value);
  }

  getAuthId() {
    if (this.getItem("A321ASD56A4SD98QW98EQWE65Q3W2") == null) {
      return "1";
    }
    return this.getItem("A321ASD56A4SD98QW98EQWE65Q3W2");
  }

  removeToken() {
    this.removeItem("9F808C5307CF273ED62EB67BD033382F");
  }

  setPageTitle(value: string) {
    this.setItem("pageTitle", value);
    this.pageTitleChanged.emit({ value: value });
  }

  getPageTitle() {
    return this.getItem("pageTitle");
  }

  removePageTitle() {
    this.removeItem("pageTitle");
  }

  setCurrentState(state: Referrals) {
    this.setItem("currentState", state);
  }

  getCurrentState() {
    return this.getItem("currentState");
  }

  setSelectedPlan(plan: Plan) {
    this.setItem("selectedPlan", plan);
  }

  getSelectedPlan() {
    return this.getItem("selectedPlan");
  }
  setLanguage(language: number) {
    this.setItem("Language", language);
  }

  getLanguage() {
    return this.getItem("Language");
  }

  setCurrentFlow(flow) {
    this.setItem("currentFlow", flow);
  }

  getCurrentFlow() {
    return this.getItem("currentFlow");
  }

  getOtpRetries() {
    return this.getItem("NUMOFRETRIES");
  }

  setOtpRetries(value) {
    this.setItem("NUMOFRETRIES", value);
  }

  getOtpRequestID() {
    return this.getItem("9F808C5307C55355ABFAABD033382F");
  }

  setOtpRequestID(value) {
    this.setItem("9F808C5307C55355ABFAABD033382F", value);
  }

  removeRequestID() {
    this.removeItem("9F808C5307C55355ABFAABD033382F");
  }

  //  ---   Inbox users filters   ---

  getInboxUsersFilters(botId: number): UsersFilters {
    return this.getItem("INBXUSRSFLTRS" + botId);
  }

  setInboxUsersFilters(botId: number, filters: UsersFilters): void {
    if (!botId) return;
    this.setItem("INBXUSRSFLTRS" + botId, filters);
  }

  setfbLoginCode(code) {
    this.setItem("9F808C5307C55345DEFAABD033382F", code);
  }
  getfbLoginCode() {
    return this.getItem("9F808C5307C55345DEFAABD033382F");
  }

  getGoogleLoginCode() {
    return this.getItem("9F808C5307C55345DEFAABD033383G");
  }

  setGoogleLoginCode(code) {
    this.setItem("9F808C5307C55345DEFAABD033383G", code);
  }

  setState(state) {
    this.setItem("currentState", state);
  }

  getSatet() {
    return this.getItem("currentState");
  }

  setAuthState(state) {
    this.setItem("currentAuthState", state);
  }

  getAuthState() {
    return this.getItem("currentAuthState");
  }

  setCurrentCustomerPartner(state) {
    this.setItem("2e1d2fbb-b3e0-4dc6-973e-2c5ead8bb9f6", state);
  }

  getCurrentCustomerPartner() {
    return this.getItem("2e1d2fbb-b3e0-4dc6-973e-2c5ead8bb9f6");
  }

  getShowTags(botId) {
    return this.getItem("Show_Tags" + botId);
  }

  setShowTags(botId, state) {
    this.setItem("Show_Tags" + botId, state);
  }

  getTransactionDate(botId) {
    return this.getItem("trans_date_filter" + botId);
  }

  setTransactionDate(botId, date) {
    this.setItem("trans_date_filter" + botId, date);
  }

  getAddonsFilterDate(botId) {
    return this.getItem("addon_date_filter" + botId);
  }

  setAddonsFilterDate(botId, date) {
    this.setItem("addon_date_filter" + botId, date);
  }

  /**
   * set account details temp info to localstorage
   */
  setNewAccountTemp(state) {
    this.setItem("ACCINFO_TEMP", state);
  }

  /**
   * get account details temp info from localstorage
   */
  getNewAccountTemp() {
    return this.getItem("ACCINFO_TEMP");
  }

  /**
   * set regeiteration step to localstorage
   */
  setRegisterStep(state) {
    this.setItem("REG_STP", state);
  }

  /**
   * get regeiteration step from localstorage
   */
  getRegisterStep() {
    return this.getItem("REG_STP");
  }

  /**
   * set login time to localstorage
   */
  setloginTime(state) {
    this.setItem("2e1d2fbb-b3e0-4dc6-973e-6767gfg66667s", state);
  }

  /**
   * get login time to localstorage
   */
  getloginTime() {
    return this.getItem("2e1d2fbb-b3e0-4dc6-973e-6767gfg66667s");
  }

  /**
   * set last interaction time to localstorage
   */
  setlastInteractionTime(state) {
    this.setItem("2e1d2fbb-b3e0-4dc6-973e-6767gfg6098007s", state);
  }

  /**
   * get last interaction time to localstorage
   */
  getlastInteractionTime() {
    return this.getItem("2e1d2fbb-b3e0-4dc6-973e-6767gfg6098007s");
  }

  /**
   * set last interaction time to localstorage
   */
  setOpenedNavbar(state: { navStatus: boolean }) {
    this.setItem("ISOPENNAV", state);
  }

  /**
   * get last interaction time to localstorage
   */
  getOpenedNavbar() {
    return this.getItem("ISOPENNAV");
  }

  /**
   * remove login time to localstorage
   */
  removeloginTime() {
    this.removeItem("2e1d2fbb-b3e0-4dc6-973e-6767gfg66667s");
  }

  saveLogManagerDateFilter(from, to) {
    localStorage.logManagerFromDate = from;
    localStorage.logManagerToDate = to;
  }
  getSavedLogManagerDateFilter() {
    return [localStorage.logManagerFromDate, localStorage.logManagerToDate];
  }
}
