export enum UnsupportedFeatures {
  All,
  playground,
  Aitraining,
  Testcases,
  Broadcast,
  Audience,
  Inbox,
  Growthtools,
  Analytics,
  Botsettings,
  TransactionsHistory,
  Logsmanager,
  Marketplace,
  Identitymanager,
  ANALYTICS_BASIC, // related to specific analytics setions
  ANALYTICS_ADVANCED, // related to specific analytics setions
  ImportExportBot, // Import and Export Bot in settings page
  Addons, // related to modals in addons sections
  EnableExtraUsers, // related to Upgrade button and extra users in plan details tab
  WorkingHours, // related to show advanced section in rules tab
  InboxNotification, // related to show inbox notfication
  FbFreePlan, // related to call api Quota
  QnaTest, // related to upload button in test cases tab
  SubscriptionInfo, // related to show number of users in plan details and allow extra users
  ToggleTags, // related to toggle sla in rules tab
  WebchatFree, // related to customize features in webchat
  Ambiguation, // related to Ambiguation button in ai training tab
  ReachLimitModal, // related to reach limit modal in addons tab
  AddAgentModal, // related to add agent modal in addons tab
  AddSkillModal, // related to add skill modal in addons tab
  WebchatEnterprise, // related to voice note feature in webchat
  Instagram, // related to show upgrade icon and page for instagram
  Twitter, // related to show upgrade icon and page for Twitter
  BotListFeatures, // related to modals like delete in bot list page
  ChannelSettingsPro, // related to upgrade channels
  ChannelSettingsEnterprise, // related to upgrade channels
  ToggleAssignCapacity, // related to toggle Assign Agent Capacity Toggle in rules tab
  ConversationAutoAssign,
  Sms, // for showing upgrade icon and page for sms configuration
  ManageBotPlans, // manage plans for owner role
  Sms_Operations, // for accessing sms operations
  HideSms, // for hide sms in settings
  BotOperations,
  InstallMarketPlace,
  BillingPlans, // hide / show billing details section
  WalletStatement, // hide / show wallet statement invoice feature
  AddManageAgentFeature, // disabled add agent button
  SubscriptionDetails,
  Wallet,
  AccessToAssignAgent,
  Email, // related to show upgrade icon and page for Email
  HideEmail, // for hide email channel in settings
  CallChannel, // show upgrade icon and page for Call Channel
  HideCall, // hide / show call channel in settings
  PhoneCallOperations, // to access retool appis for system admin role
  BotInformationTab, // hide / show Bot information in settings
  ChannelsTab, // hide / show Channel in settings
  SubscriptionTab, // hide / show Subscription tab in settings
  TeamManagerTab, // hide / show Team Manager in settings
  AttributesTab, // hide / show Attributes in settings
  IntegrationsTab, // hide / show Integrations in settings
}
